<template>
  <div class="judges">
    <div class="login-box">
      <h1>评委专家登录</h1>
      <div class="login-form">
        <el-form
          label-position="center"
          hide-required-asterisk
          :rules="rules"
          label-width="80px"
          :model="formLabelAlign"
          ref="formLabelAlign"
        >
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.trim="formLabelAlign.mobile"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code" id="spendCode">
            <el-input
              v-model.trim="formLabelAlign.code"
              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            >
              <el-button slot="append" v-if="countDownShow"
                >{{ time }}秒后可重新发送</el-button
              >
              <el-button slot="append" v-else @click="codeHandle()"
                >发送验证码</el-button
              >
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="密码" prop='password'>
            <el-input v-model="formLabelAlign.password" show-password></el-input>
          </el-form-item> -->
          <el-form-item id="mylogin">
            <el-button
              id="loginbtn"
              type="primary"
              @click="submitForm('formLabelAlign')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <footerCard></footerCard>
  </div>
</template>

<script>
import { judgesLogin } from "../JudegeAPI/login";
import { spendCode } from "../API/login.js";
import { setTokenJ } from "../../assets/js/Storage-J";
import footerCard from "../../components/footerCard.vue";
export default {
  data() {
    return {
      countDownShow: false,
      time: 60, // 倒计时
      formLabelAlign: {
        // 13671214284 普通护士
        // 17600288025 普通技师
        // 13641282521 专家
        mobile: "",
        code: "",
        // password: '123456'
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "change" },
          { min: 5, message: "长度应该大于 5 个数字", trigger: "blur" },
        ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'change' },
        //   { min: 6, message: '长度应该大于 6 个字符', trigger: 'blur' },
        // ],
      },
    };
  },
  created() {
    this.generateRandomString();
  },
  mounted() {},
  components: {
    footerCard,
  },
  methods: {
    generateRandomString() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // 定义可用字符集合

      for (let i = 0; i < 20; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length); // 生成0到字符集合长度减一之间的随机整数
        result += characters[randomIndex]; // 将随机字符添加到结果字符串
      }
      if (localStorage.getItem("Authorization")) return;
      localStorage.setItem("Authorization", result);
    },
    // 表单验证
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await judgesLogin(this.formLabelAlign);
          if (res.code !== "0000") {
            return this.$message(res.msg);
          }
          setTokenJ(res.data);
          console.log(res.data);
          this.$message.success("登陆成功");
          return this.$router.push({ name: "JudgesPage" });
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    async codeHandle() {
      let str = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
      var state = this.formLabelAlign.mobile.search(str);
      if (this.formLabelAlign.mobile !== "" && state !== -1) {
        const res = await spendCode({
          mobile: this.formLabelAlign.mobile,
          type: 4,
        });
        if (res.data.code == "0000") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        // 60秒倒计时
        this.countDownShow = true;
        let timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            this.countDownShow = false;
            this.time = 60;
            return clearInterval(timer);
          }
        }, 1000);
      }
      return this.$refs.formLabelAlign.validateField("mobile");
    },
  },
};
</script>

<style scoped lang="scss">
.judges {
  .login-box {
    width: 834px;
    background: #ffffff;
    border-radius: 10px;
    min-height: 500px;
    box-shadow: -10px 0px 32px 32px rgb(24 25 34 / 6%);
    margin: 80px auto;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    h1 {
      text-align: center;
      font-size: 26px;
      color: #05258e;
      font-weight: bold;
      height: 80px;
      line-height: 80px;
      background: url("../../assets/image/login_bg.webp");
      background-position: center, left;
      background-repeat: no-repeat, no-repeat;
      margin: 0 0px 40px 0;
      border-radius: 10px 10px 0px 0px;
    }
    .login-form {
      width: 510px;
      margin: 0 auto;
      @media screen and (max-width: 540px) {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .el-form-item {
        height: 60px;
      }
      ::v-deep .el-form-item__label {
        line-height: 60px;
        border: 1px solid #dcdfe6;
        border-right: none;
        height: 60px;
      }
      ::v-deep .el-input__inner {
        line-height: 60px;
        height: 60px;
        border-radius: 0;
      }
      #loginbtn {
        padding: 0;
        width: 510px;
        color: #ffffff;
        font-size: 18px;
        height: 60px;
        background: #293f8e;
        border-radius: 2px;
        @media screen and (max-width: 540px) {
          width: 100%;
        }
      }
      #mylogin {
        margin-top: 60px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
      }
      #spendCode {
        ::v-deep .el-input-group__append {
          color: #5b2d9b;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
